<template>
  <el-row class="movie-list">
    <el-row style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
      <el-card class="box-card" style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
        <div slot="header" class="clearfix">
          <span>添加图片</span>
          <el-button style="float: right; padding: 10px" type="text" @click="onReturnAlbum">返回图片稿件列表</el-button>
        </div>
        <div class="text item">
          <el-upload
            v-if="actionUrl !== null"
            :action="actionUrl"
            :headers="imgHeaders"
            :data="imgData"
            :file-list="uploadImages"
            :multiple="true"
            :limit="limit"
            :with-credentials="true"
            list-type="picture-card"
            :before-upload="handleBeforeUpload"
            :on-success="handleOnSuccess"
            :on-error="handleOnError"
            :on-remove="handleOnRemove"
            :on-preview="handleOnPreview"
          >
            <i class="el-icon-plus" />
          </el-upload>
        </div>
      </el-card>
    </el-row>
    <el-row style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
      <el-col :md="24">
        <div v-if="dataList.length !== 0">
          <el-col
            v-for="(image, index) in dataList"
            :key="image.thumbnailUrl"
            :md="6"
            :sm="12"
            :xs="12"
            style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px"
          >
            <el-card :body-style="{ padding: '0px' }" class="card">
              <div class="imgs">
                <el-image
                  lazy
                  fit="cover"
                  class="coverImg"
                  :src="image.thumbnailUrl"
                  @click="showImages(index)"
                />
              </div>
            </el-card>
          </el-col>
        </div>
      </el-col>
    </el-row>
    <el-pagination
      :small="screenWidth <= 768"
      hide-on-single-page
      layout="prev, pager, next"
      :page-size="pageSize"
      :current-page="currentPage"
      :total="totalSize"
      @current-change="handleCurrentChange"
      @prev-click="handleCurrentChange"
      @next-click="handleCurrentChange"
    />
  </el-row>
</template>

<script>
import { addAlbumImage, getImagePostItems } from '@/api/image'
import { getPhotoChannelInfo } from '@/api/file'

var imageFileMap = new Map()
export default {
  name: 'ImagePostEdit',
  data() {
    return {
      actionUrl: null,
      /** *********************************************************************/
      imgHeaders: {
        Authorization: ''
      },
      imgData: {
        channelCode: 105
      },
      dialogImageUrl: '',
      dialogVisible: false,
      uploadImages: [],
      /** *********************************************************************/
      // 屏幕宽度, 为了控制分页条的大小
      screenWidth: document.body.clientWidth,
      currentPage: 1,
      pageSize: 12,
      totalSize: 0,
      albumId: null,
      data: null,
      dataList: [],
      postType: 0,
      imageCount: 0,
      limit: 0
    }
  },
  created() {
    document.title = '编辑相册稿件'
    this.albumId = this.$route.params.albumId
    this.getAlbumItemsWrapper()
    getPhotoChannelInfo().then(res => {
      if (res.code === 0) {
        const resData = res.data
        this.actionUrl = resData.ossUrl
        this.imgHeaders.Authorization = 'Bearer ' + resData.token
        this.imgData.channelCode = resData.channelCode
      } else {
        this.$notify({
          title: '提示',
          message: '获取 OSS 服务器地址失败, 暂时无法上传图片',
          type: 'error',
          duration: 3000
        })
      }
    }).catch(error => {
      this.$notify({
        title: '获取 OSS 服务器地址失败, 暂时无法上传图片',
        message: error.message,
        type: 'warning',
        duration: 3000
      })
    })
  },
  mounted() {
    // 当窗口宽度改变时获取屏幕宽度
    window.onresize = () => {
      return () => {
        window.screenWidth = document.body.clientWidth
        this.screenWidth = window.screenWidth
      }
    }
  },
  methods: {
    handleCurrentChange(pageNumber) {
      this.currentPage = pageNumber
      this.dataList = []
      this.getAlbumItemsWrapper()
      // 回到顶部
      scrollTo(0, 0)
    },
    getAlbumItemsWrapper() {
      getImagePostItems(this.albumId, this.currentPage).then(resp => {
        if (resp.code === 0) {
          this.data = resp.data
          const images = resp.data.images
          this.dataList = images.list
          this.totalSize = images.totalSize
          this.imageCount = images.length
          this.limit = 40 - images.length
        }
      })
    },
    /** *********************************************************************/
    handleBeforeUpload(file) {
      var isJPG = false
      if (file.type === 'image/jpeg' || file.type === 'image/webp' ||
        file.type === 'image/gif' || file.type === 'image/png') {
        isJPG = true
      }

      const isLt2M = file.size / 1024 / 1024 < 100
      if (!isJPG) {
        this.$message.error('图片只能是 jpeg/webp/gif/png 格式!')
      }
      if (!isLt2M) {
        this.$message.error('图片大小不能超过 100MB!')
      }
      return isJPG && isLt2M
    },
    handleOnSuccess(res, file) {
      if (res.code === 0) {
        const resData = res.data
        imageFileMap.set(file.name, resData.uploadId)

        const jsonData = {}
        jsonData.albumId = this.data.albumId
        jsonData.imageFileId = resData.uploadId
        addAlbumImage(jsonData).then(resp => {
          if (resp.code === 0) {
            this.$notify({
              title: '图片已添加到相册',
              type: 'info',
              duration: 1000
            })
          } else {
            this.$notify({
              title: '图片添加失败',
              message: resp.msg,
              type: 'warning',
              duration: 3000
            })
          }
        }).catch(error => {
          this.$notify.error({
            title: '图片添加错误',
            message: error.message,
            type: 'error',
            duration: 3000
          })
        })
      } else {
        this.$notify({
          title: '提示',
          message: '图片上传失败，请重试！' + res.msg,
          type: 'warning',
          duration: 3000
        })
      }
    },
    handleOnError(err, file, fileList) {
      const errMsg = JSON.parse(err.message)
      this.$notify({
        title: '图片上传失败',
        message: errMsg.msg,
        type: 'error',
        duration: 3000
      })
    },
    handleOnRemove(file, fileList) {
      imageFileMap.delete(file.name)
    },
    handleOnPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    /** *********************************************************************/
    showImages(index) {
      const imageUrls = []
      for (const i of this.dataList) {
        imageUrls.push(i.originalUrl)
      }

      this.$viewerApi({
        images: imageUrls,
        options: {
          initialViewIndex: index,
          movable: true,
          fullscreen: false,
          keyboard: true
        }
      })
    },
    onReturnAlbum() {
      this.$router.push('/post/image/list')
    }
  }
}
</script>

<style scoped>
/*处于手机屏幕时*/
@media screen and (max-width: 768px){
  .movie-list {
    padding-top: 8px;
    padding-left: 0.5%;
    padding-right: 0.5%;
  }

  .coverImg {
    height: 120px !important;
  }
}

.movie-list {
  padding-top: 15px;
  padding-left: 6%;
  padding-right: 6%;
}

.coverImg {
  width: 100%;
  height: 240px;
  display: block;
}

.card {
  margin-bottom: 20px;
  transition: all 0.6s; /*所有属性变化在0.6秒内执行动画*/
}

.imgs {
  position: relative;
}
</style>
